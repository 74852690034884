import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import LineArrowRight from "../Icons/LineArrowRight"
import PrimaryLinkButton from "../Button/PrimaryLinkButton"
import BreakpointUp from "../Media/BreakpointUp"
import Badge from "../Badge"
import { GatsbyImage, getImage  } from "gatsby-plugin-image"

const Card = styled.div`  
  position: relative;
  background-color:#fff;
  box-shadow:0 20px 40px rgba(3,51,102,0.15);
  margin-bottom:30px;
  border-radius: 6px;
  align-items: center;
  height: calc(100% - 30px);
  padding:15px;
  ${BreakpointUp.xl`
    padding:30px;
  `}
`

const CardFigure = styled.figure`
  position: relative;  
  margin: 0;
  overflow:hidden;
  padding:15px;
  ${BreakpointUp.xl`
    padding:20px;
  `}
  .gatsby-image-wrapper{
    border-radius:6px;
    display: block;
    > div{
      max-width:100% !important;
    }
  }
  
`
const CardBody = styled.div`
  position: relative;
  padding:15px;
  ${BreakpointUp.lg`
    padding:20px;
  `}
  .badge{
    margin-bottom: 10px;
  }
`
const CardTitle = styled.div`
  margin: 0 0 5px;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  ${BreakpointUp.lg`
    font-size: 28px;
    line-height: 36px;
  `}
  a {
    color: #000;
    &:hover,
    &:focus {
      color:#033366;
    }
  }
`
const PostedDate = styled.span`  
  display:block;
  font-size:12px;
  font-weight:700;
  margin-bottom:15px;
  text-transform:uppercase;
`
const CardHeading = styled.div`  
 
`
const CardText = styled.div`
  margin-bottom:15px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3;
  ${BreakpointUp.xl`
  -webkit-line-clamp: 4;
  `}
  ${BreakpointUp.xxl`
    font-size:18px;
    line-height:32px;
  `}  
`

const CardToolbar = styled.div`
  .btn{
    text-transform:uppercase;
    font-size:14px;
  }
`

function ArticleCard({ data }) {
  const blogImage = getImage(data.image)
  return (
    <Card className="card review-card">
      <CardBody className="card-body">
        <CardHeading className="card-heading">
          <Badge text={data.category.name} />
          <CardTitle className="card-title"><Link to={data.url}>{data.title}</Link></CardTitle>
          <PostedDate>{data.publishDate}</PostedDate>
        </CardHeading>
        <CardText className="card-desc">
          <div dangerouslySetInnerHTML={{__html: data.description.childMarkdownRemark.html}} />
        </CardText>
        <CardToolbar><Link to={data.url}><PrimaryLinkButton icon={<LineArrowRight />} text="Read Full Article" /></Link></CardToolbar>
      </CardBody>
      <CardFigure className="card-img">
        <GatsbyImage image={blogImage} alt={data.title} /> 
      </CardFigure>
    </Card>
  )
}

export default ArticleCard
