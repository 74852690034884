import React, { useState } from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import {
  Container,
  Section,
  SectionDescription,
  SectionTitle,
  SectionInnerWhite,
} from "../../components/Section"
import DefaultButton from "../../components/Button/DefaultButton"
import BreakpointUp from "../../components/Media/BreakpointUp"
import LineArrowRight from "../../components/Icons/LineArrowRight"
import BreakpointDown from "../../components/Media/BreakpointDown"
import PopoverBuyingProcess from "../../components/Popover/EasyBuyingProcess"
import RequestModal from "../../components/RequestModal"

const Card = styled.div`
  position: relative;
  text-align: center;
  padding: 30px 30px 30px 15px;
  height: 100%;
  background: #fff;
  transition: all 0.3s ease-in-out;
  ${BreakpointUp.xl`
        margin-bottom:40px;
        padding:30px 45px 30px 30px;
    `}
  ${BreakpointUp.xxl`
        padding:60px 45px 60px 30px;
    `}
    ${BreakpointDown.sm`
     padding:30px 30px 0px 15px;
    `}
    &.active {
    z-index: 5;
    button {
      span {
        transform: translateY(-50%) rotate(45deg);
        background: #000;
        &:after {
          background: #000;
        }
      }
    }
  }

  &:after {
    content: "";
    position: absolute;
    background-color: #fff;
    right: 0;
    top: auto;
    bottom: 0;
    width: 100%;
    height: 30px;
    ${BreakpointUp.xl`
            right: 0;
            top: 0;
            bottom: 0;
            width: 30px;
            height: 100%;
        `}
    ${BreakpointUp.xxl`
            width: 40px;
        `}
  }
  &:before {
    content: "";
    box-shadow: 0 15px 25px rgba(3, 51, 102, 0.2);
    position: absolute;
    top: auto;
    right: auto;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    width: 80%;
    height: 10px;
    ${BreakpointUp.xl`
            top:50%;
            right:0;
            left:auto;
            bottom:auto;
            transform:translateY(-50%);
            width:10px;
            height:80%;
        `}
    ${BreakpointUp.xxl`
            width:15px;
        `}
         ${BreakpointDown.lg`
           width:100%;
           z-index: 1;
        `}
  }
`
const CardTitle = styled.h3`
  position: relative;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 26px;
  @media (min-width: 768px) {
    font-size: 20px;
    line-height: 28px;
  }
  @media (min-width: 1600px) {
    font-size: 24px;
    line-height: 30px;
  }
`
const CardBody = styled.div`
  position: relative;
`
const CardText = styled.p`
  max-width: 720px;
  width: 100%;
  margin: 0 auto 10px auto;
`
const CardFigure = styled.figure`
  position: relative;
  margin: 0 0 20px;
  height: 84px;
  max-width: 100px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  .gatsby-image-wrapper {
    display: inline-block;
  }
`
const CardAction = styled.div`
  position: absolute;
  z-index: 1;
  right: auto;
  top: auto;
  left: 50%;
  transform: translateX(-50%);
  ${BreakpointUp.xl`
        top:250px;
        left:auto;
        right:-25px;
        transform:translate(0, 0);
    `}
  ${BreakpointDown.lg`
         bottom: -17px;
    `}
    button {
    background-color: #fff;
    width: 60px;
    height: 60px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0.5px 0px 0px 0px rgb(3 51 102 / 20%);
    span {
      background: #d51333;
      height: 12px;
      position: absolute;
      width: 4px;
      top: 50%;
      transform: translateY(-50%);
      transition: all 0.4s ease-in-out;
      &:after {
        background: #d51333;
        content: "";
        height: 4px;
        left: -4px;
        position: absolute;
        top: 4px;
        width: 12px;
      }
    }
    ${BreakpointDown.lg`
            width:40px;
            height:40px;
        `}
  }
`
const SectionGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
  position: relative;
`
const SectionGridCol = styled.div`
  width: 100%;
  padding: 0 10px 40px 10px;
  &:last-child {
    padding: 0 10px 60px 10px;
  }
  ${BreakpointUp.xl` 
        padding:0 10px;
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    `}
  ${BreakpointDown.sm`
       padding-bottom:0;
    `}
`
const ExploreMore = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 340px;
  width: 100%;
  display: block;
  margin: 20px auto 0 auto;

  ${BreakpointUp.sm` 
        position: absolute;
        left:50%;
        transform:translateX(-50%);
        bottom: -22px;
        margin:0;
    `}
  ${BreakpointUp.lg` 
        bottom: -26px;
    `}
    button, .btn {
    width: 100%;
  }
`
const CheckedIcon = styled.div`
  position: absolute;
  top: 22px;
  right: -2px;
`

const BuyProcessSection = ({ location }) => {
  const [activeOlay, setActiveOlay] = useState(null)
  const [isQuoteVisible, setIsQuoteVisible] = useState(false)
  function handlePopover(key) {
    if (activeOlay === key) {
      return setActiveOlay(null)
    }
    setActiveOlay(key)
  }
  const HandleModalOpen = () => {
    setIsQuoteVisible(true)
    document.querySelector("body").classList.add("modal-open")
  }
  const HandleModalClose = () => {
    setIsQuoteVisible(false)
    document.querySelector("body").classList.remove("modal-open")
  }

  return (
    <Section
      xpt="30px"
      mpt="30px"
      pt="15px"
      xpb="100px"
      mpb="100px"
      pb="60px"
      bgColor="transparent"
    >
      <Container maxWidth="100%" pl="3%" pr="3%">
        <SectionInnerWhite pl="0" mpl="0" xpl="0" pr="0" mpr="0" xpr="0">
          <Container maxWidth="100%" pl="3%" pr="3%">
            <SectionTitle>Take Advantage of Our Easy Purchasing Program</SectionTitle>
            <SectionDescription mb="30px" maxWidth="1496px">Buying a building from Liberty Metal Structures is quick and easy, thanks to our simple process! Create your custom structure in just a few minutes by following our step-by-step process:</SectionDescription>
          </Container>
          <SectionGrid>
            <SectionGridCol>
              <Card className={`card ${activeOlay === 0 ? "active" : ``}`}>
                <CardFigure className="card-img">
                  <StaticImage
                    src="../../svg/building-style-size.svg"
                    placeholder="blurred"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="Find a Building Style and Size"
                  />
                  {activeOlay === 0 && (
                    <CheckedIcon>
                      <StaticImage
                        src="../../svg/tick.svg"
                        placeholder="blurred"
                        quality={95}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="Request a Quote for Your Dream Building"
                      />
                    </CheckedIcon>
                  )}
                </CardFigure>
                <CardBody className="card-body">
                  <CardTitle>Discover the Possibilities</CardTitle>
                  <CardText>
                    Find the building you’re dreaming of in a variety of frames,
                    dimensions, and roof styles! Once you’ve fallen in love with
                    one of our many beautiful designs, or created one of your
                    own, we’re ready to move ahead!
                  </CardText>
                </CardBody>
                <CardAction>
                  <button
                    type="button"
                    aria-label="button"
                    onClick={() => handlePopover(0)}
                  >
                    <span></span>
                  </button>
                </CardAction>
              </Card>
              <PopoverBuyingProcess isActive={activeOlay === 0 && true} />
            </SectionGridCol>
            <SectionGridCol>
              <Card className={`card ${activeOlay === 1 ? "active" : ""}`}>
                <CardFigure className="card-img">
                  <StaticImage
                    src="../../svg/customise-according-requirement.svg"
                    placeholder="blurred"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="Customise According to Your Requirment"
                  />
                  {activeOlay === 1 && (
                    <CheckedIcon>
                      <StaticImage
                        src="../../svg/tick.svg"
                        placeholder="blurred"
                        quality={95}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="Request a Quote for Your Dream Building"
                      />
                    </CheckedIcon>
                  )}
                </CardFigure>
                <CardBody className="card-body">
                  <CardTitle>I Want It That Way </CardTitle>
                  <CardText>
                    Explore our range of colors and siding! Do you need an open
                    barn or an enclosed workshop? Customize your dream metal
                    building in our 3D viewer, or call one of our experts for
                    more ideas!
                  </CardText>
                </CardBody>
                <CardAction>
                  <button
                    type="button"
                    aria-label="button"
                    onClick={() => handlePopover(1)}
                  >
                    <span></span>
                  </button>
                </CardAction>
              </Card>
              <PopoverBuyingProcess isActive={activeOlay === 1 && true} />
            </SectionGridCol>
            <SectionGridCol>
              <Card className={`card ${activeOlay === 2 ? "active" : ""}`}>
                <CardFigure className="card-img">
                  <StaticImage
                    src="../../svg/request-quote.svg"
                    placeholder="blurred"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="Request a Quote for Your Dream Building"
                  />
                  {activeOlay === 2 && (
                    <CheckedIcon>
                      <StaticImage
                        src="../../svg/tick.svg"
                        placeholder="blurred"
                        quality={95}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="Request a Quote for Your Dream Building"
                      />
                    </CheckedIcon>
                  )}
                </CardFigure>
                <CardBody className="card-body">
                  <CardTitle>Let’s Make It Happen</CardTitle>
                  <CardText>
                    It’s time to make the call and hear how Liberty Metal
                    Structures will turn your dream into reality! Affordable
                    financing and fantastic pricing are part of what makes our
                    company the best in the business!
                  </CardText>
                </CardBody>
                <CardAction>
                  <button
                    type="button"
                    aria-label="button"
                    onClick={() => handlePopover(2)}
                  >
                    <span></span>
                  </button>
                </CardAction>
              </Card>
              <PopoverBuyingProcess isActive={activeOlay === 2 && true} />
            </SectionGridCol>
            <SectionGridCol>
              <Card className={`card ${activeOlay === 3 ? "active" : ""}`}>
                <CardFigure className="card-img">
                  <StaticImage
                    src="../../svg/delivery-lead-time.svg"
                    placeholder="blurred"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="Building Delivery "
                  />
                  {activeOlay === 3 && (
                    <CheckedIcon>
                      <StaticImage
                        src="../../svg/tick.svg"
                        placeholder="blurred"
                        quality={95}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="Request a Quote for Your Dream Building"
                      />
                    </CheckedIcon>
                  )}
                </CardFigure>
                <CardBody className="card-body">
                  <CardTitle>Worth the Wait </CardTitle>
                  <CardText>
                    Liberty Metal Buildings is accurate and reliable. While
                    other companies struggle to meet their quotas during these
                    difficult economic times, we put our customers first. You
                    can expect quick lead times and great communication along
                    the way!
                  </CardText>
                </CardBody>
                <CardAction>
                  <button
                    type="button"
                    aria-label="button"
                    onClick={() => handlePopover(3)}
                  >
                    <span></span>
                  </button>
                </CardAction>
              </Card>
              <PopoverBuyingProcess isActive={activeOlay === 3 && true} />
            </SectionGridCol>
            <SectionGridCol>
              <Card className={`card ${activeOlay === 4 && `active`}`}>
                <CardFigure className="card-img">
                  <StaticImage
                    src="../../svg/got-building-door.svg"
                    placeholder="blurred"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="Now Got Your "
                  />
                  {activeOlay === 4 && (
                    <CheckedIcon>
                      <StaticImage
                        src="../../svg/tick.svg"
                        placeholder="blurred"
                        quality={95}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="Request a Quote for Your Dream Building"
                      />
                    </CheckedIcon>
                  )}
                </CardFigure>
                <CardBody className="card-body">
                  <CardTitle>Dreams Come True </CardTitle>
                  <CardText>
                    Our customers are our family! Our experts will work with you
                    to provide a simple and enjoyable installation process.
                    Experience the difference with Liberty Metal Buildings!
                  </CardText>
                </CardBody>
                <CardAction>
                  <button
                    type="button"
                    aria-label="button"
                    onClick={() => handlePopover(4)}
                  >
                    <span></span>
                  </button>
                </CardAction>
              </Card>
              <PopoverBuyingProcess isActive={activeOlay === 4 && true} />
            </SectionGridCol>
          </SectionGrid>
        </SectionInnerWhite>
        <ExploreMore>
          <button type="button" aria-label="button" onClick={HandleModalOpen}>
            <DefaultButton
              text="Now Request a Quote"
              icon={<LineArrowRight />}
              size="lg"
            />
          </button>
        </ExploreMore>
      </Container>
      <RequestModal location={location} isVisible={isQuoteVisible} onClose={HandleModalClose} />
    </Section>
  )
}

export default BuyProcessSection
