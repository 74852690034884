import React from "react"
import PhoneIcon from "../Icons/PhoneIcon"
import PrimaryButton from "../Button/PrimaryButton"
import { StaticImage } from "gatsby-plugin-image"
import {
  Section,
  Container,
  SectionTitle,
  SectionDescription,
} from "../Section"
import {
  SectionExpert,
  SectionToolbar,
  ExpertBackground,
  SectionExpertContent,
} from "../CallExpert"

function ExpertCall({ title, description }) {
  return (
    <Section pt="0" pb="0" bgColor="transparent">
      <Container maxWidth="100%" pl="3%" pr="3%">
        <SectionExpert>
          <ExpertBackground>
            <StaticImage
              src="../../images/expert-bg.jpg"
              placeholder="blurred"
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Expert"
            />
          </ExpertBackground>
          <SectionExpertContent>
            <SectionTitle color="#fff" maxWidth="890px">
              {title ? title : "WE ARE HERE FOR YOU!"}
            </SectionTitle>
            <SectionDescription color="#fff" mb="30px">
              {description ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: description.childMarkdownRemark.html,
                  }}
                />
              ) : (
                "Don’t hesitate to reach out! Our experts are standing by, ready to answer any questions! Our customers are our priority!"
              )}
            </SectionDescription>
            <SectionToolbar>
              <a href="tel:8004407309" aria-label="Phone">
                <PrimaryButton
                  textAfter="(800) 440-7309"
                  icon={<PhoneIcon />}
                  size="lg"
                />
              </a>
            </SectionToolbar>
          </SectionExpertContent>
        </SectionExpert>
      </Container>
    </Section>
  )
}

export default ExpertCall
