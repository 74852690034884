import React, { useState } from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import {
  Container,
  Section,
  SectionDescription,
  SectionTitle,
  SectionInnerWhite,
} from "../../components/Section"
import ArrowDownIcon from "../../components/Icons/ArrowDownIcon"
import BreakpointUp from "../../components/Media/BreakpointUp"
import BreakpointDown from "../../components/Media/BreakpointDown"
import PopoverGetClarity from "../../components/Popover/PopoverGetClarity"

const SectionInnergray = styled.div`
  position: relative;
  max-width: 1200px;
  width: 100%;
  margin-inline: auto;
  margin-top: 30px;
`
const SectionGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
  justify-content: center;
`
const SectionGridCol = styled.div`
  /* position: relative; */
  width: 100%;
  flex: 0 0 auto;
  padding: 0 10px;
  ${BreakpointUp.sm`
     width:50%;
    `}
  ${BreakpointUp.lg` 
       width:20%;
    `}
    ${BreakpointDown.md`
      position:relative;
    `}
`

const Card = styled.div`
  background-color: #fff;
  position: relative;
  text-align: center;
  margin-bottom: 30px;
  border: 2px solid #c1d1e3;
  display: flex;
  padding: 10 25px;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  text-align: center;
  cursor:pointer;
  ${BreakpointUp.lg` 
    height:100%;
  `}
  &.active {
    z-index: 4;
    button {
      svg {
        transform: rotate(180deg);
        fill: #d51333;
      }
    }
  }
`
const CardFigure = styled.figure`
  margin: 0 0 15px;
  display: flex;
  align-items: flex-end;
  .gatsby-image-wrapper {
    display: inline-block;
  }
`

const CardTitle = styled.div`
  margin-bottom: 0;
  color: #033366;
  font-weight: 800;
  letter-spacing: 1px;
  line-height: 20px;
`
const CardBody = styled.div`
  position: relative;
  z-index: 1;
  padding: 15px;
`
const CardAction = styled.div`
  background-color: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: -15px;
  box-shadow: 0 3px 0px #c1d1e3;
`
const BorderBox = styled.div`
  border-top: 2px dashed #03336633;
  margin-top: 60px;
  padding-top: 40px;
`
const BorderBoxTitle = styled.strong`
  font-size: 24px;
  color: #000;
`
const CardLead = styled.div`
  margin-bottom: 0;
  color: #666;
  line-height: 20px;
  font-size: 14px;
`

function GetClarity() {
  const [activeOlay, setActiveOlay] = useState(null)
  function handlePopover(key) {
    if (activeOlay === key) {
      return setActiveOlay(null)
    }
    setActiveOlay(key)
  }

  return (
    <Section pt="0" xpb="60px" mpb="40px" pb="30px" bgColor="transparent">
      <Container maxWidth="100%" pl="3%" pr="3%">
        <SectionInnerWhite className="section-white">
          <SectionTitle>The Options are Endless</SectionTitle>
          <SectionDescription mb="40px">
            <p>Flexible workshops, gorgeous garages, versatile studios, and more! From eco-friendly homes to meticulously fabricated warehouses, there are so many varieties of metal buildings! </p>
            <p>Don’t be overwhelmed! Read on to discover the unique benefits of different structures and how they can work for you!</p>
            <BorderBox>
              <BorderBoxTitle>DISCOVER the DIFFERENCE!</BorderBoxTitle>
              <p>
                <strong>Metal buildings are the future of quality construction; read on to see why!</strong>
              </p>
              <SectionInnergray>
                <SectionGrid>
                  <SectionGridCol>
                    <Card
                      className={`install-card ${
                        activeOlay === 1 ? "active" : ""
                      }`}
                      onClick={() => handlePopover(1)}
                    >
                      <CardBody>
                        <CardFigure className="card-img">
                          <StaticImage
                            src="../../images/carports-type.png"
                            placeholder="blurred"
                            alt="Floating Docks"
                          />
                        </CardFigure>
                        <CardTitle>Carports</CardTitle>
                      </CardBody>
                      <CardAction>
                        <button                          
                          type="button"
                          aria-label="button"
                        >
                          <ArrowDownIcon />
                        </button>
                      </CardAction>
                    </Card>
                    <PopoverGetClarity
                      content={`This isn’t your father’s old carport. With an array of roofing options, from curved edges to A-frames, your metal carport will be a beautifully designed addition to your home! Liberty Metal Structures offers an incredible variety of dimensions, from 12’ to 70’ wide, so you can protect several vehicles at once with our durable, high-quality steel.`}
                      isActive={activeOlay === 1 && true}
                    />
                  </SectionGridCol>
                  <SectionGridCol>
                    <Card
                      className={`install-card ${
                        activeOlay === 2 ? "active" : ""
                      }`}
                      onClick={() => handlePopover(2)}
                    >
                      <CardBody>
                        <CardFigure className="card-img">
                          <StaticImage
                            src="../../images/garages-type.png"
                            placeholder="blurred"
                            alt="Floating Docks"
                          />
                        </CardFigure>
                        <CardTitle>Garages</CardTitle>
                      </CardBody>
                      <CardAction>
                        <button                         
                          type="button"
                          aria-label="button"
                        >
                          <ArrowDownIcon />
                        </button>
                      </CardAction>
                    </Card>
                    <PopoverGetClarity
                      content={`Your automotive investments deserve the best protection that high-quality steel framing can provide. At Liberty Metal Structures, our buildings are wind and snow rated, and can withstand anything that nature throws at them! Whether you order a lovely, enclosed A-frame or purchase a vertical-roof garage, you can rest assured that your cars and trucks are safe. And with our customizable colors, you can create a building that will complement your home!`}
                      isActive={activeOlay === 2 && true}
                    />
                  </SectionGridCol>
                  <SectionGridCol>
                    <Card
                      className={`install-card ${
                        activeOlay === 3 ? "active" : ""
                      }`}
                      onClick={() => handlePopover(3)}
                    >
                      <CardBody>
                        <CardFigure className="card-img">
                          <StaticImage
                            src="../../images/barn-type.png"
                            placeholder="blurred"
                            alt="Floating Docks"
                          />
                        </CardFigure>
                        <CardTitle>Barns</CardTitle>
                      </CardBody>
                      <CardAction>
                        <button                          
                          type="button"
                          aria-label="button"
                        >
                          <ArrowDownIcon />
                        </button>
                      </CardAction>
                    </Card>
                    <PopoverGetClarity
                      content={`Look no further for your ideal barn! Metal is an excellent choice because it is durable, fire-resistant, and beautiful. Whether you prefer a drop-down roof frame or the soaring vertical roof, the customizable options at Liberty Mutual Structures are endless. With open frames for animals, or enclosed sides to protect your equipment, it has never been easier to order the perfect metal barn for your individual needs.`}
                      isActive={activeOlay === 3 && true}
                    />
                  </SectionGridCol>
                  <SectionGridCol>
                    <Card
                      className={`install-card ${
                        activeOlay === 4 ? "active" : ""
                      }`}
                      onClick={() => handlePopover(4)}
                    >
                      <CardBody>
                        <CardFigure className="card-img">
                          <StaticImage
                            src="../../images/rv-covers-type.png"
                            placeholder="blurred"
                            alt="Floating Docks"
                          />
                        </CardFigure>
                        <CardTitle>RV Covers</CardTitle>
                      </CardBody>
                      <CardAction>
                        <button                          
                          type="button"
                          aria-label="button"
                        >
                          <ArrowDownIcon />
                        </button>
                      </CardAction>
                    </Card>
                    <PopoverGetClarity
                      content={`Liberty Metal Structures is the best in the industry for high-quality, durable steel structures. Your RV is a home away from home, and it should have one of our beautiful metal RV covers. You want the best protection against all kinds of weather, and our non-corrosive steel will deliver!`}
                      isActive={activeOlay === 4 && true}
                    />
                  </SectionGridCol>
                  <SectionGridCol>
                    <Card
                      className={`install-card ${
                        activeOlay === 5 ? "active" : ""
                      }`}
                      onClick={() => handlePopover(5)}
                    >
                      <CardBody>
                        <CardFigure className="card-img">
                          <StaticImage
                            src="../../images/metal-building-type.png"
                            placeholder="blurred"
                            alt="Floating Docks"
                          />
                        </CardFigure>
                        <CardTitle>Other Buildings</CardTitle>
                      </CardBody>
                      <CardAction>
                        <button
                          type="button"
                          aria-label="button"
                        >
                          <ArrowDownIcon />
                        </button>
                      </CardAction>
                    </Card>
                    <PopoverGetClarity
                      content={`Metal buildings are so much more than sheds and carports. In the fast-paced modern era of construction, industrial and commercial clients are turning to steel for its durability, sustainability, and reliability! Beautifully designed clear span structures offer incredible enormous space and flexibility. Steel buildings are far more cost-effective than ordinary timber buildings and are constructed far more quickly. The steel framing from Liberty Metal Structures is fire-resistant and rated for wind and snow – our buildings can handle whatever Mother Nature throws at them! Need a utility building or a warehouse? We’re here for you!`}
                      isActive={activeOlay === 5 && true}
                    />
                  </SectionGridCol>
                </SectionGrid>
              </SectionInnergray>
            </BorderBox>
            <BorderBox>
              <BorderBoxTitle>
              Heavy Metal: Which Roof Do You Need?
              </BorderBoxTitle>
              <SectionInnergray>
                <SectionGrid>
                  <SectionGridCol>
                    <Card
                      className={`install-card ${
                        activeOlay === 6 ? "active" : ""
                      }`}
                      onClick={() => handlePopover(6)}
                    >
                      <CardBody>
                        <CardFigure className="card-img">
                          <StaticImage
                            src="../../images/vertical-roof-style.png"
                            placeholder="blurred"
                            alt="Floating Docks"
                          />
                        </CardFigure>
                        <CardTitle>Best</CardTitle>
                        <CardLead>Vertical Roof Style</CardLead>
                      </CardBody>
                      <CardAction>
                        <button
                          type="button"
                          aria-label="button"
                        >
                          <ArrowDownIcon />
                        </button>
                      </CardAction>
                    </Card>
                    <PopoverGetClarity
                      content={`The star of our roof family, the vertical roof is tough enough to withstand any weather! Because the high-quality panels are installed vertically, precipitation and debris are guided directly to the ground. The roof is engineered with cross braces, ridge caps, and a hat channel.`}
                      isActive={activeOlay === 6 && true}
                    />
                  </SectionGridCol>
                  <SectionGridCol>
                    <Card
                      className={`install-card ${
                        activeOlay === 7 ? "active" : ""
                      }`}
                      onClick={() => handlePopover(7)}
                    >
                      <CardBody>
                        <CardFigure className="card-img">
                          <StaticImage
                            src="../../images/a-frame-roof-style.png"
                            placeholder="blurred"
                            alt="Floating Docks"
                          />
                        </CardFigure>
                        <CardTitle>Better</CardTitle>
                        <CardLead>A-FRAME Roof Style</CardLead>
                      </CardBody>
                      <CardAction>
                        <button
                          type="button"
                          aria-label="button"
                        >
                          <ArrowDownIcon />
                        </button>
                      </CardAction>
                    </Card>
                    <PopoverGetClarity
                      content={`The A-frame roof, or boxed-eave style, is engineered with horizontal panels. This style is rated for high winds, but not for excessive snow or rain. Because this option doesn’t require hat channel pieces, it is less expensive than the vertical roof.`}
                      isActive={activeOlay === 7 && true}
                    />
                  </SectionGridCol>
                  <SectionGridCol>
                    <Card
                      className={`install-card ${
                        activeOlay === 8 ? "active" : ""
                      }`}
                      onClick={() => handlePopover(8)}
                    >
                      <CardBody>
                        <CardFigure className="card-img">
                          <StaticImage
                            src="../../images/regular-roof-style.png"
                            placeholder="blurred"
                            alt="Floating Docks"
                          />
                        </CardFigure>
                        <CardTitle>Good</CardTitle>
                        <CardLead>Regular Roof Style</CardLead>
                      </CardBody>
                      <CardAction>
                        <button                         
                          type="button"
                          aria-label="button"
                        >
                          <ArrowDownIcon />
                        </button>
                      </CardAction>
                    </Card>
                    <PopoverGetClarity
                      content={`The standard roofing option at Liberty Metal Structures is affordable and stable, the solution for almost every storage situation. Whether you prefer the curved slope or the single span, these roofs are durable and long-lasting. The steel panels are placed horizontally and are recommended for mild weather conditions. `}
                      isActive={activeOlay === 8 && true}
                    />
                  </SectionGridCol>
                </SectionGrid>
              </SectionInnergray>
            </BorderBox>
          </SectionDescription>
        </SectionInnerWhite>
      </Container>
    </Section>
  )
}

export default GetClarity
