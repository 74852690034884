import React, { useState } from "react"
import { graphql } from "gatsby"

import Layout from "../layouts"
import Seo from "../components/seo"
import HeroSection from "../sections/Home/HeroSection"
import ProductSection from "../sections/Product/ProductSection"
import PrepInstallingSection from "../sections/Home/PrepInstallingSection"
import BuyProcessSection from "../sections/Home/BuyProcessSection"
import AboutReviewSection from "../sections/Home/AboutReviewSection"
import ExpertSection from "../components/Expert"
import RequestModal from "../components/RequestModal"
import ArticleSection from "../sections/Home/ArticleSection"
import GetClarity from "../sections/Home/GetClarity"

const IndexPage = ({ location, data }) => {
  const [isQuoteVisible, setIsQuoteVisible] = useState(false)

  const HandleModalOpen = () => {
    setIsQuoteVisible(true)
    document.querySelector("body").classList.add("modal-open")
  }
  const HandleModalClose = () => {
    setIsQuoteVisible(false)
    document.querySelector("body").classList.remove("modal-open")
  }

  return (
    <Layout location={location}>
      <Seo
        title="Liberty Metal Structures - Leading Dealer of Metal Buildings"
        description="Liberty Metal Structures is a leader in Metal Buildings providing Metal Carports, Barns, Garages, Workshops, and Commercial Buildings at the best prices."
      />
      <HeroSection />
      <ProductSection
        data={data.allContentfulProduct.edges}
        openQuoteModal={HandleModalOpen}
      />
      <PrepInstallingSection />
      <BuyProcessSection location={location} />
      <AboutReviewSection
        data={data.allContentfulReview.edges}
        states={data.allContentfulState.edges}
      />
      <ExpertSection />
      <ArticleSection data={data.allContentfulBlog.edges} />
      <GetClarity />
      <RequestModal
        location={location}
        isVisible={isQuoteVisible}
        onClose={HandleModalClose}
      />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query IndexPageQuery {
    allContentfulProduct {
      edges {
        node {
          id
          name
          url
          description {
            childMarkdownRemark {
              html
            }
          }
          category {
            name
          }
          subCategory {
            name
          }
          sku
          roofType {
            name
          }
          width
          length
          height
          price
          availableFinancing
          availableRto
          image {
            gatsbyImageData(quality: 100, placeholder: BLURRED)
          }
          warranty {
            warranty
          }
          otherSpec {
            otherSpec
          }
        }
      }
    }
    allContentfulState {
      edges {
        node {
          name
          url
        }
      }
    }
    allContentfulBlog {
      edges {
        node {
          title
          url
          category {
            name
          }
          publishDate(fromNow: true)
          description {
            childMarkdownRemark {
              html
            }
          }
          image {
            gatsbyImageData(quality: 100, placeholder: BLURRED)
          }
          content {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulReview {
      edges {
        node {
          title
          author
          authorRole
          authorImage {
            gatsbyImageData(quality: 100, placeholder: BLURRED)
          }
          date(fromNow: true)
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
