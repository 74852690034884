import React, { useState } from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import {
  Container,
  Section,
  SectionDescription,
  SectionTitle,
  SectionLead,
  SectionInnerWhite,
} from "../../components/Section"
import ArrowRightIcon from "../../components/Icons/ArrowRightIcon"
import CloseIcon from "../../components/Icons/CloseIcon"
import BreakpointUp from "../../components/Media/BreakpointUp"

import PopoverInstallation from "../../components/Popover/PopoverInstallation"
import BreakpointDown from "../../components/Media/BreakpointDown"

const SectionInnergray = styled.div`
  background-color: #f5faff;
  position: relative;
  box-shadow: 0 25px 50px rgba(3, 51, 102, 0.2);
  padding: 30px 4.166%;
  ${BreakpointUp.xl`    
    padding:60px 4.166%;
  `}
  ${BreakpointUp.xxl`    
    padding:100px 8.333%;
  `}
`
const SectionGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
`
const SectionGridCol = styled.div`
  /* position: relative; */
  width: 100%;
  padding: 0 10px;
  ${BreakpointUp.sm`
     width:50%;
    `}
  ${BreakpointUp.lg` 
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  `}
  ${BreakpointDown.md`
    position:relative;
  `}
`
const Grid = styled.ul`
  /* position: relative; */
  margin: 0;
  padding: 0;
  list-style: none;
`
const GridItem = styled.li`
  margin: 0;
  padding: 0;
  ${BreakpointDown.md`
    position: relative; 
    display:none;
    margin:0 0 20px;
    &.tab-active{
      display:block;
    }
    .gatsby-image-wrapper{ 
      margin-bottom:30px;
    }
  `}
  .grid-panel {
    position: relative;
    &.active {
      z-index: 4;
    }
  }

  + li {
    margin-top: -17px;
    .gatsby-image-wrapper {
      &:after {
        content: "";
        position: absolute;
        left: 75%;
        top: 25px;
      }
    }
  }
`
const GridPanel = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  ${BreakpointDown.sm`
    text-align:center;
  `}
`
const GridPanelLeft = styled.div`
  position: relative;
  width: 100%;
  ${BreakpointUp.md` 
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  `}
  .gatsby-image-wrapper {
    position: relative;
    filter: drop-shadow(0 25px 50px #999);
    /* ${BreakpointUp.lg` 
            margin-top: -16px;
        `} */
  }
`
const GridPanelRight = styled.div`
  position: relative;
  width: 100%;
  ${BreakpointUp.md` 
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    `}
  h3 {
    position: relative;
    margin-bottom: 10px;
    margin-top: 20px;
    ${BreakpointUp.lg` 
      margin-bottom:10px;
    `}
    &:before {
      ${BreakpointUp.md` 
        content: "";
        position: absolute;
        right: 105%;
        top: 25px;
        background-image: linear-gradient(to right,transparent 50%,#033366 0%);
        background-position: 0px 0px;
        background-size: 9px 1px;
        background-repeat: repeat-x;
        height: 2px;
        z-index: 1;
        width: 160px;
      `}
    }
  }
  button {
    width: 40px;
    height: 40px;
    background-color: #eef6fe;
    ${BreakpointUp.md` 
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      background-color:transparent;
    `}
    svg {
      fill: #d51333;
      width: 14px;
      height: 10px;
    }
    &:hover {
      background-color: #eef6fe;
      svg {
        fill: #033366;
      }
    }
  }
`

const GridPanelBody = styled.div`
  position: relative;
  padding: 0 15px;
  ${BreakpointUp.md` 
        padding:0 30px;
    `}
  ${BreakpointUp.xl` 
        padding:0 50px;
    `}
`
const GridPanelTitle = styled.h3`
  color: #000;
`
const GridPanelDesc = styled.div`
  > p{
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3;
  }
`
const Card = styled.div`
  background-color: #fff;
  position: relative;
  text-align: center;
  margin-bottom: 40px;
  box-shadow: 0 25px 50px rgb(3 51 102 / 20%);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  ${BreakpointUp.lg` 
    height:100%;
  `}
  &.active {
    z-index: 4;
    button {
      svg {
        transform: rotate(180deg);
      }
    }
  }
`
const CardFigure = styled.figure`
  position: relative;
  margin: 0 0 20px;
  height: 106px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  .gatsby-image-wrapper {
    display: inline-block;
  }
  ${BreakpointDown.sm`
      max-width:130px;
      height: 76px;
      margin-bottom:0;
    `}
`
const CardLead = styled.div`
  margin-bottom: 0;
  color: #d51333;
  font-size: 18px;
  line-height: 24px;
  @media (min-width: 1200px) {
    font-size: 20px;
    line-height: 24px;
  }
  @media (min-width: 1600px) {
    font-size: 22px;
    line-height: 28px;
  }
`
const CardTitle = styled.div`
  margin-bottom: 0;
  font-size: 18px;
  line-height: 24px;
  @media (min-width: 1200px) {
    font-size: 20px;
    line-height: 24px;
  }
  @media (min-width: 1600px) {
    font-size: 22px;
    line-height: 28px;
  }
`
const CardBody = styled.div`
  padding: 15px 15px 25px;
  @media (min-width: 1600px) {
    padding: 15px 15px 45px;
  }
  ${BreakpointDown.sm`
      display:flex;
      align-items:center;
      width:100%;
      justify-content:space-between;
    `}
`
const MobileTabs = styled.div`
  display: none;
  justify-content: space-between;
  margin-bottom: 60px;
  position: relative;
  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: -20px;
    height: 1px;
    left: -15px;
    right: -15px;
    border-bottom: 1px dashed #000000;
  }
  ${BreakpointDown.md`
  display:flex;
 `}
`
const MobileTabsItem = styled.div`
  font-size: 14px;
  color: #000;
  font-family: "Barlow", sans-serif;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  font-weight: bold;
  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: -22px;
    height: 6px;
    left: 0;
    right: 0;
    background: transparent;
    z-index: 1;
  }
  &.tab-active {
    &:after {
      background: #d51333;
    }
  }
`
const CardContent = styled.div``

function PrepInstallingSection() {
  const [activeOlay, setActiveOlay] = useState(null)

  // Tabs
  function handleTab(key) {
    document.querySelectorAll(".grid-item, .tab-item").forEach(item => {
      item.classList.remove("tab-active")
      document.querySelector(`#${key}`).classList.add("tab-active")
      document.querySelector(`#tab-item-${key}`).classList.add("tab-active")
    })
  }
  function handlePopover(key) {
    if (activeOlay === key) {
      return setActiveOlay(null)
    }
    setActiveOlay(key)
  }

  return (
    <Section pt="0" xpb="30px" mpb="30px" pb="15px" bgColor="transparent">
      <Container maxWidth="100%" pl="3%" pr="3%">
        <SectionInnerWhite className="section-white">
          <SectionTitle>Preparing Your Site & Building a Quality Foundation</SectionTitle>
          <SectionDescription mb="40px">
            <p>
            You’ve ordered your custom metal building, chosen the perfect colors, and surveyed the perfect spot! But, before your dream can become a reality, you need a strong foundation that will protect your investment in any weather. Do you need an asphalt pad, or will simple gravel support be enough? There’s a lot to consider!</p>
            <p>Site clearance and preparation must be completed before professional installation can begin, and, depending on your local ordinances, you may need a permit. While all the factors involved may seem overwhelming, our fantastic customer service experts are here to help!</p>
            <p>Read on to discover which foundation is the best choice for your metal building:</p>
          </SectionDescription>

          <Grid>
            <MobileTabs>
              <MobileTabsItem
                className="tab-active tab-item"
                id="tab-item-concrete"
                onClick={() => handleTab("concrete")}
              >
                Concrete
              </MobileTabsItem>
              <MobileTabsItem
                className="tab-item"
                id="tab-item-asphalt"
                onClick={() => handleTab("asphalt")}
              >
                Asphalt{" "}
              </MobileTabsItem>
              <MobileTabsItem
                className="tab-item"
                id="tab-item-ground"
                onClick={() => handleTab("ground")}
              >
                Ground
              </MobileTabsItem>
              <MobileTabsItem
                className="tab-item"
                id="tab-item-gravel"
                onClick={() => handleTab("gravel")}
              >
                Gravel
              </MobileTabsItem>
            </MobileTabs>
            {/* tabs for mobile  */}
            <GridItem className="grid-item tab-active" id="concrete">
              <GridPanel
                className={`grid-panel ${activeOlay === 0 ? "active" : ""}`}
              >
                <GridPanelLeft>
                  <StaticImage
                    src="../../images/concrete.png"
                    placeholder="blurred"
                    alt="concrete"
                  />
                </GridPanelLeft>
                <GridPanelRight>
                  <GridPanelBody>
                    <GridPanelTitle>Concrete</GridPanelTitle>
                    <GridPanelDesc>
                      <p>Concrete is an excellent choice for your foundation due to its durable and stable nature. While it is more expensive than other options, when it is professionally installed, you can rest assured that your building will be secure! Depending on what you are storing, concrete provides a dry, raised floor to protect your belongings from the elements.</p>
                    </GridPanelDesc>
                    <button type="button" aria-label="button" onClick={() => handlePopover(0)}>
                      {activeOlay === 0 ? <CloseIcon /> : <ArrowRightIcon />}
                    </button>
                  </GridPanelBody>
                </GridPanelRight>
              </GridPanel>
              <PopoverInstallation content={`<p>Concrete is an excellent choice for your foundation due to its durable and stable nature. While it is more expensive than other options, when it is professionally installed, you can rest assured that your building will be secure! Depending on what you are storing, concrete provides a dry, raised floor to protect your belongings from the elements.</p><p>Our experts advise that you hire a professional to pour your concrete foundation. They can communicate with you about your building’s requirements, know when to pour concrete with reference to temperature and humidity, and ensure it is properly set before your building’s installation day.</p><p>Once your beautiful custom metal building arrives, simply bolt and anchor it to your new foundation!</p>`} isActive={activeOlay === 0 && true} />
            </GridItem>
            <GridItem className="grid-item" id="asphalt">
              <GridPanel
                className={`grid-panel ${activeOlay === 1 ? "active" : ""}`}
              >
                <GridPanelLeft>
                  <StaticImage
                    src="../../images/asphalt.png"
                    placeholder="blurred"
                    alt="asphalt"
                  />
                </GridPanelLeft>
                <GridPanelRight>
                  <GridPanelBody>
                    <GridPanelTitle>Asphalt</GridPanelTitle>
                    <GridPanelDesc>
                    <p>If you prefer the appearance of asphalt, you’ll be happy to discover that it is slightly cheaper than concrete. However, if you have a large metal building, you may need to upgrade to concrete. Asphalt is a very durable option, making it popular with commercial and personal consumers. Bear in mind that it requires regular maintenance and yearly sealant to prevent cracks.</p>
                    </GridPanelDesc>
                    <button type="button" aria-label="button" onClick={() => handlePopover(1)}>
                        {activeOlay === 1 ? <CloseIcon /> : <ArrowRightIcon />}
                    </button>
                  </GridPanelBody>
                </GridPanelRight>
              </GridPanel>
              <PopoverInstallation content={`<p>If you prefer the appearance of asphalt, you’ll be happy to discover that it is slightly cheaper than concrete. However, if you have a large metal building, you may need to upgrade to concrete. Asphalt is a very durable option, making it popular with commercial and personal consumers. Bear in mind that it requires regular maintenance and yearly sealant to prevent cracks.</p><p>As with concrete, anchoring your steel building to asphalt is simple and will provide an excellent, stable foundation.</p>`} isActive={activeOlay === 1 && true} />
            </GridItem>
            <GridItem className="grid-item" id="ground">
              <GridPanel
                className={`grid-panel ${activeOlay === 2 ? "active" : ""}`}
              >
                <GridPanelLeft>
                  <StaticImage
                    src="../../images/ground.png"
                    placeholder="blurred"
                    alt="Ground"
                  />
                </GridPanelLeft>
                <GridPanelRight>
                  <GridPanelBody>
                    <GridPanelTitle>Ground</GridPanelTitle>
                    <GridPanelDesc>
                      <p>Perhaps, your prefab building is small, your lot is level and dry, and you are comfortable with a simple ground foundation. We recommend clearing all brush and debris from the site several days in advance and keeping an eye on how the bare soil responds to weather and water. Your judgment matters when it comes to your project, and our experts will be happy to discuss different anchoring and installation options!</p>
                    </GridPanelDesc>
                    <button type="button" aria-label="button" onClick={() => handlePopover(2)}>
                      {activeOlay === 2 ? <CloseIcon /> : <ArrowRightIcon />}
                    </button>
                  </GridPanelBody>
                </GridPanelRight>
              </GridPanel>
              <PopoverInstallation content={`<p>Perhaps, your prefab building is small, your lot is level and dry, and you are comfortable with a simple ground foundation. We recommend clearing all brush and debris from the site several days in advance and keeping an eye on how the bare soil responds to weather and water. Your judgment matters when it comes to your project, and our experts will be happy to discuss different anchoring and installation options!</p>`} isActive={activeOlay === 2 && true} />
            </GridItem>
            <GridItem className="grid-item" id="gravel">
              <GridPanel
                className={`grid-panel ${activeOlay === 3 ? "active" : ""}`}
              >
                <GridPanelLeft>
                  <StaticImage
                    src="../../images/gravel.png"
                    placeholder="blurred"
                    alt="Gravel"
                  />
                </GridPanelLeft>
                <GridPanelRight>
                  <GridPanelBody>
                    <GridPanelTitle>Gravel</GridPanelTitle>
                    <GridPanelDesc>
                      <p>Are you searching for a quality budget option? While it may not be your first choice, gravel is a great beginning foundation. As with all foundation options, be sure your space is level and cleared of all debris before delivery. We recommend purchasing enough gravel to provide a buffer zone around your building and allowing it some time to settle before adding a second layer. Once your gravel is leveled and tamped down, your building can be secured atop with drive anchors.</p>
                    </GridPanelDesc>
                    <button type="button" aria-label="button" onClick={() => handlePopover(3)}>
                      {activeOlay === 3 ? <CloseIcon /> : <ArrowRightIcon />}
                    </button>
                  </GridPanelBody>
                </GridPanelRight>
              </GridPanel>
              <PopoverInstallation content={`<p>Are you searching for a quality budget option? While it may not be your first choice, gravel is a great beginning foundation. As with all foundation options, be sure your space is level and cleared of all debris before delivery. We recommend purchasing enough gravel to provide a buffer zone around your building and allowing it some time to settle before adding a second layer. Once your gravel is leveled and tamped down, your building can be secured atop with drive anchors.</p><p>The best part of a gravel foundation? It is easy to pour a concrete foundation over the gravel in the future when your time and budget allows! In the meantime, a properly leveled gravel foundation will keep your building secure.</p>
              <p>Liberty Metal Structures is committed to our quality products, and that includes ensuring they are resting on the best foundation! A proper foundation is essential to your building’s structural integrity. Of course, this means that our buildings cannot be installed simply anywhere. Without proper base anchors, our professionals cannot ensure the safety and durability of our structure. There are some spaces our experts are unable to install our buildings listed below:</p>
              <p><strong>INSTALLATION IS NOT RECOMMENDED FOR:</strong></p>
              <ul><li>Floating Docks</li><li>Uneven Surfaces or Land</li><li>Slopes or Grades over 12 degrees</li><li>Raised Blocks or Other Structures</li><li>Our experts cannot install over three feet of insulation</li></ul>`} isActive={activeOlay === 3 && true} />
            </GridItem>
          </Grid>
        </SectionInnerWhite>
      </Container>
      <Container maxWidth="100%" pl="3%" pr="3%">
        <SectionInnergray>
          <SectionLead mb="40px" color="#033366" maxWidth="900px">Make sure your ground is professionally levelled before the unit can be install. We will not be able to install the structure if any of the following are indicated at the construction site.</SectionLead>
          <SectionGrid>
            <SectionGridCol>
              <Card className="install-card">
                <CardBody>
                  <CardFigure className="card-img">
                    <StaticImage
                      src="../../svg/floating-docks.svg"
                      placeholder="blurred"
                      alt="Floating Docks"
                    />
                  </CardFigure>
                  <CardContent>
                    <CardLead className="h6">No Installs On </CardLead>
                    <CardTitle className="h6">Floating Docks</CardTitle>
                  </CardContent>
                </CardBody>
              </Card>
            </SectionGridCol>
            <SectionGridCol>
              <Card className="install-card">
                <CardBody>
                  <CardFigure className="card-img">
                    <StaticImage
                      src="../../svg/unlevel-land.svg"
                      placeholder="blurred"
                      alt="Unlevel Land"
                    />
                  </CardFigure>
                  <CardContent>
                    <CardLead className="h6">No Installs On </CardLead>
                    <CardTitle className="h6">Unlevel Land</CardTitle>
                  </CardContent>
                </CardBody>                
              </Card>
            </SectionGridCol>
            <SectionGridCol>
              <Card className="install-card">
                <CardBody>
                  <CardFigure className="card-img">
                    <StaticImage
                      src="../../svg/slopes-over.svg"
                      placeholder="blurred"
                      alt="Slopes Over 12"
                    />
                  </CardFigure>
                  <CardContent>
                    <CardLead className="h6">No Installs On </CardLead>
                    <CardTitle className="h6">Slopes Over 12</CardTitle>
                  </CardContent>
                </CardBody>               
              </Card>
            </SectionGridCol>
            <SectionGridCol>
              <Card className="install-card">
                <CardBody>
                  <CardFigure className="card-img">
                    <StaticImage
                      src="../../svg/stacked-blocks.svg"
                      placeholder="blurred"
                      alt="Stacked Blocks"
                    />
                  </CardFigure>
                  <CardContent>
                    <CardLead className="h6">No Installs On </CardLead>
                    <CardTitle className="h6">Stacked Blocks</CardTitle>
                  </CardContent>
                </CardBody>
              </Card>
            </SectionGridCol>
            <SectionGridCol>
              <Card className="install-card">
                <CardBody>
                  <CardFigure className="card-img">
                    <StaticImage
                      src="../../svg/3-feet-maximum.svg"
                      placeholder="blurred"
                      alt="3 Feet Maximum"
                    />
                  </CardFigure>
                  <CardContent>
                    <CardLead className="h6">Wall Installation</CardLead>
                    <CardTitle className="h6">- 3 Feet Maximum</CardTitle>
                  </CardContent>
                </CardBody>
              </Card>
            </SectionGridCol>
          </SectionGrid>
        </SectionInnergray>
      </Container>
    </Section>
  )
}

export default PrepInstallingSection