import React from "react"
import styled from 'styled-components'

const PopOverlay = styled.div`
  opacity: 0;
  visibility: hidden;
  transition: all 500ms ease-in-out;
  pointer-events: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background: rgba(235,244,252,.9);
  transition: all 500ms ease-in-out;
  z-index: 3;
  &.active{
    opacity: 1;
    visibility: visible;
    transform:scale(1.2)
  }
`

function PopoverBuyingProcess({ isActive }) {
    return(
      <PopOverlay className={`pop-overlay ${isActive ? 'active' : ''}`} />
    )
}

export default PopoverBuyingProcess