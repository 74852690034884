import React from "react"
import styled from "styled-components"
import {
  Container,
  Section,
  SectionDescription,
  SectionTitle,
  SectionInnerWhite,
} from "../../components/Section"
import ProductCardList from "../../components/ProductCardList"
import BreakpointDown from "../../components/Media/BreakpointDown"

const SectionInnerWhites = styled(SectionInnerWhite)`
  &:before {
    content: "";
    background: -moz-linear-gradient(
      top,
      rgba(22, 67, 114, 1) 0%,
      rgba(125, 185, 232, 0) 100%
    );
    background: -webkit-linear-gradient(
      top,
      rgba(22, 67, 114, 1) 0%,
      rgba(125, 185, 232, 0) 100%
    );
    background: linear-gradient(
      to bottom,
      rgba(22, 67, 114, 1) 0%,
      rgba(125, 185, 232, 0) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#164372', endColorstr='#007db9e8',GradientType=0 );
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 440px;
    ${BreakpointDown.sm`
          height:15%;
        `}
  }
`
function ProductSection({ data, openQuoteModal }) {
  return (
    <Section pt="0" xpb="60px" mpb="40px" pb="30px" bgColor="#F5FAFF">
      <Container maxWidth="100%" pl="3%" pr="3%">
        <SectionInnerWhites>
          <SectionTitle color="#fff">
            Marvelous Multi-Purpose Metal Structures
          </SectionTitle>
          <SectionDescription
            maxWidth="1496px"
            mb="40px"
            color="rgba(255,255,255,0.6)"
          >
            <p>
              Liberty Metal Structures provides the highest quality metal
              buildings in the industry, and it’s time to find yours! We provide
              a variety of steel structures, from tough metal sheds to soaring
              steel barns, from sleek metal carports to designer steel
              workshops. Do you want to be the architect of your own
              multi-purpose space? If you can dream it, we can build it!
            </p>
          </SectionDescription>
          <ProductCardList data={data} openQuoteModal={openQuoteModal} />
        </SectionInnerWhites>
      </Container>
    </Section>
  )
}

export default ProductSection
