import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import { Container, Section } from "../../components/Section"
import BreakpointUp from "../../components/Media/BreakpointUp"
//import { Link } from "gatsby"
//import Badge from "../../components/Badge"
// import BreakpointDown from "../../components/Media/BreakpointDown"
// import DefaultButton from "../../components/Button/DefaultButton"
// import LineArrowRight from "../../components/Icons/LineArrowRight"
// import ReviewCard from "../../components/ReviewCard"

const SectionAbout = styled.div`
  background-color: #fff;
  padding: 0 4.166%;
  position: relative;
  box-shadow: 0 25px 50px rgb(3 51 102 / 20%);
  ${BreakpointUp.xxl`    
        padding:0 8.333%;
    `}
`
const Background = styled.div`
  width: 75%;
  height: 100%;
  position: absolute;
  top: 0px;
  right: 0px;
  opacity: 0.1;
  .gatsby-image-wrapper {
    height: 100%;
    display: block;
  }
  &:after {
    content: "";
    background: -moz-linear-gradient(
      left,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    background: -webkit-linear-gradient(
      left,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 );
    position: absolute;
    top: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
  }
`
const AboutGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  margin: 0 -10px;
`
const AboutGridLeft = styled.div`
  position: relative;
  width: 100%;
  padding: 0 10px;
  display: none;
  ${BreakpointUp.lg` 
        flex: 0 0 30%;
        max-width: 30%;
        display:flex;
    `}
  ${BreakpointUp.xl` 
        flex: 0 0 40%;
        max-width: 40%;
    `}
`
const AboutGridRight = styled.div`
  position: relative;
  width: 100%;
  padding: 30px 10px;
  ${BreakpointUp.lg` 
        flex: 0 0 70%;
        max-width: 70%;
    `}
  ${BreakpointUp.xl` 
        padding:60px 10px;
        flex: 0 0 60%;
        max-width: 60%;
    `}
    ${BreakpointUp.xxl` 
        padding:100px 10px;
        flex: 0 0 50%;
        max-width: 50%;
    `}
`
const AboutContent = styled.div`
  text-align: center;
  padding-bottom: 20px;
  @media (min-width: 1200px) {
    padding-bottom: 30px;
  }
  p {
    strong {
      font-style: italic;
      color: #444;
      font-weight: 500;
      font-size: 18px;
      line-height: 32px;
      @media (min-width: 1200px) {
        font-size: 20px;
        line-height: 36px;
      }
      @media (min-width: 1600px) {
        font-size: 22px;
        line-height: 40px;
      }
    }
  }
`
// const AboutState = styled.div`
//   text-align: center;
//   strong {
//     display: block;
//     font-size: 22px;
//     font-weight: 700;
//     line-height: 28px;
//     margin-bottom: 20px;
//     color: #000;
//   }
// `
// const BadgeHorizontal = styled.ul`
//   list-style: none;
//   margin: -5px;
//   padding: 0;
// `
// const BadgeHorizontalItem = styled.li`
//   margin: 0;
//   padding: 5px;
//   display: inline-block;
// `

// const SectionReview = styled.div`
//     background-color:#F5FAFF;
//     box-shadow:0 25px 50px rgba(3,51,102,0.2);
//     padding:40px 4.166%;
//     ${BreakpointUp.xl`
//         padding:60px 4.333%;
//     `}
//     ${BreakpointUp.xxl`
//         padding:90px 8.333%;
//     `}
// `
// const SectionGrid = styled.div`
//     display: flex;
//     flex-wrap: wrap;
//     margin:0 -10px;

// `
// const SectionGridCol = styled.div`
//     position: relative;
//     width:100%;
//     padding:0 10px;
//     ${BreakpointUp.lg`
//         flex-basis: 0;
//         flex-grow: 1;
//         max-width: 100%;
//     `}
//     ${BreakpointDown.sm`
//         &:last-child{
//             .review-card{
//                 &:after{
//                     display:none;
//                 }
//             }
//         }
//     `}
//     .review-card{
//         .date{
//             display:none;
//         }
//         .card-desc{
//         display: -webkit-box;
//         -webkit-line-clamp: 8;
//         -webkit-box-orient: vertical;
//         overflow: hidden;
//             p{
//                 color: #444;
//                 font-weight: 500;
//                 font-style: italic;
//             }
//         }
//     }
// `
// const ExploreMore = styled.div`
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     max-width: 340px;
//     width: 100%;
//     display: block;
//     margin:20px auto 0 auto;
//     ${BreakpointUp.sm`
//         position: absolute;
//         left:50%;
//         transform:translateX(-50%);
//         bottom: -22px;
//         margin:0;
//     `}
//     ${BreakpointUp.lg`
//         bottom: -26px;
//     `}
//     .btn{
//         width:100%;
//     }
// `

const AboutReviewSection = ({ data, states }) => {
  return (
    <Section pt="0" xpb="60px" mpb="40px" pb="30px" bgColor="transparent">
      <Container maxWidth="100%" pl="3%" pr="3%">
        <SectionAbout>
          <Background>
            <StaticImage
              src="../../images/about-bg.jpg"
              placeholder="blurred"
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="About"
            />
          </Background>
          <AboutGrid>
            <AboutGridLeft>
              <StaticImage
                src="../../images/statue-liberty.png"
                placeholder="blurred"
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Statue Liberty"
              />
            </AboutGridLeft>
            <AboutGridRight>
              <AboutContent>
                <h2>Our Story: The Community at Liberty Metal Buildings</h2>
                <p>
                  <strong>
                    “Liberty Metal Buildings has proven itself as an industry
                    leader by providing outstanding service to every customer!
                    Our quality products and fantastic prices are only part of
                    the story; when we commit to a project, we know that we are
                    building more than a structure. We are building a
                    relationship!”
                  </strong>
                </p>
                <p>
                  Do you need answers about a custom option? Our experts will be
                  happy to help. Do you have concerns about delivery and
                  installation accuracy? Our representatives will be with you
                  every step of the way!
                </p>
                <p>
                  Make the call today! Discover for yourself that the experts at
                  Liberty Metal Buildings are passionate professionals who put
                  our customers first!
                </p>
              </AboutContent>
              {/* <AboutState>
                <strong>
                  Providing Best Metal Buildings Across the United States
                </strong>
                <BadgeHorizontal>
                  {states.map((item, i) => (
                    <BadgeHorizontalItem key={i}>
                      <Link to={item.node.url}>
                        <Badge text={item.node.name} />
                      </Link>
                    </BadgeHorizontalItem>
                  ))}
                </BadgeHorizontal>
              </AboutState> */}
            </AboutGridRight>
          </AboutGrid>
        </SectionAbout>
      </Container>
      {/* <Container maxWidth="100%" pl="3%" pr="3%">
                <SectionReview>
                    <SectionTitle mb="30px">Customer’s Reviews</SectionTitle>
                    <SectionGrid>
                        {
                            data.map((item, i) => {
                                if (i < 3) {
                                    return <SectionGridCol key={i}><ReviewCard data={item.node} /></SectionGridCol>    
                                }
                            })
                        }
                    </SectionGrid>
                </SectionReview>
                <ExploreMore>
                    <Link to='/reviews'><DefaultButton text="View All Reviews" icon={< LineArrowRight />} size="lg" /></Link>
                </ExploreMore>
            </Container> */}
    </Section>
  )
}

export default AboutReviewSection
