import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import PrimaryButton from "../../components/Button/PrimaryButton"
import BreakpointUp from "../../components/Media/BreakpointUp"
import BreakpointDown from "../../components/Media/BreakpointDown"
import { StaticImage } from "gatsby-plugin-image"
import { Container, Section } from "../../components/Section"
import ArrowRightIcon from "../../components/Icons/ArrowRightIcon"
import Badge from "../../components/Badge"
import HandPointer from "../../svg/handpointer.svg"
import BannerHero from "../../images/banner-hero.jpg"

const Hero = styled.div`
  position: relative;
  padding-top: 64px;
  @media (min-width: 1200px) {
    padding-top: 94px;
  }
  @media (min-width: 1600px) {
    padding-top: 114px;
  }
  ${BreakpointDown.sm`
	  padding-top:44px;
	`}
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    background: url(${BannerHero});
    background-size: 100%;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    background: rgba(3, 51, 102, 0.92);
    width: 100%;
    height: 100%;
  }
`
const HeroCaption = styled.div`
  position: relative;
  z-index: 2;
  text-align: center;
  color: #fff;
  padding: 0 15px;
`
const CaptionTop = styled.div`
  margin: 0;
`
const CaptionSubTitle = styled.div`
  font-weight: 700;
  font-family: "Barlow", sans-serif;
  margin-bottom: 10px;

  font-size: 20px;
  line-height: 24px;
  @media (min-width: 768px) {
    font-size: 22px;
    line-height: 28px;
  }
  @media (min-width: 1600px) {
    font-size: 24px;
    line-height: 30px;
  }
  strong {
    font-weight: 700;
    color: #d59300;
  }
`
const CaptionTitle = styled.h1`
  max-width: 1190px;
  width: 100%;
  margin: 0 auto 30px;
  color: #fff;
  ${BreakpointUp.lg`	 
	  max-width:890px;
    `}
  ${BreakpointUp.xxl`	 
	  max-width:1090px;
    `}
`

const CaptionMiddle = styled.div`
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  width: 100%;
  justify-content: center;
  flex-direction: column-reverse;
  ${BreakpointUp.lg`
		flex-direction: inherit;
	`}
`
const Feature = styled.div`
  position: relative;
  width: 100%;
  text-align: left;
  margin-top: -60px;
  ${BreakpointUp.lg`
		margin-top:-100px;
	`}

  ${BreakpointUp.lg`
		flex: 0 0 50%;
   		max-width: 50%;
		margin-top:0;
	`}
	${BreakpointUp.xl`
		flex: 0 0 25%;
   		max-width: 25%;
	`}
	${BreakpointUp.xxl`
		flex: 0 0 16.666667%;
		max-width: 16.666667%;
	`}
`
const FeatureBody = styled.div`
  padding: 30px 0;
  ${BreakpointUp.sm`
		padding:30px;
	`}
  ${BreakpointUp.lg`
		padding:60px 30px 30px;
	`}
	${BreakpointUp.xxl`
		padding:100px 30px 30px;
	`}
	${BreakpointDown.sm`
			margin-top: 20px;
			max-width: 186px;
			margin-left: auto;
			margin-right: auto;
	`}
`
const FeatureTitle = styled.div`
  position: relative;
  display: block;
  margin-bottom: 10px;
  color: #fff;
  span {
    display: inline-block;
    position: relative;
    &:after {
      ${BreakpointUp.lg`
				content: "";
				position: absolute;
				left: 100%;
				top: 50%;
				background-image: linear-gradient(to right,transparent 50%,rgb(255,255,255) 0%);
				background-position: 0px 0px;
				background-size: 9px 1px;
				background-repeat: repeat-x;
				height: 2px;
				transform: translateY(-50%);
				margin-top: 3px;
				z-index:1;
				width: 100%;
			`}
    }
  }
`
const ListFeature = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  line-height: 28px;
  ${BreakpointUp.sm`
		font-size: 16px;
		line-height: 30px;
	`}
  ${BreakpointDown.lg`
		column-count: 2;
		column-gap: 5px;
	`}
		${BreakpointDown.sm`
		column-count: 1;
		column-gap: 5px;
	`}
	& li {
    padding-left: 20px;
    vertical-align: middle;
    margin: 0;
    position: relative;
    + li {
      margin-top: 5px;
      ${BreakpointUp.sm`
				margin-top:10px;
			`}
    }
    &:before {
      content: url(${HandPointer});
      display: inline-block;
      margin-left: -20px;
      width: 30px;
      vertical-align: top;
      height: 30px;
      line-height: 30px;
    }
  }
`
const ListFeatureItem = styled.li`
  margin: 0;
`

const CaptionFigure = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  ${BreakpointUp.xxl`
		flex: 0 0 auto;
		width: auto;
		max-width: 100%;
	`}
`
const CaptionOption = styled.div`
  position: relative;
  width: 100%;
  ${BreakpointUp.lg`
		flex: 0 0 25%;
   		max-width: 25%;
	`}
  display:none;
  ${BreakpointUp.xl`
		display:block;
	`}
  ${BreakpointUp.xxl`
		flex: 0 0 16.666667%;
		max-width: 16.666667%;
	`}
`
const CaptionOptionBody = styled.div`
  padding: 60px 30px 30px;
  ${BreakpointUp.xxl`
		padding:100px 30px 30px; 	
	`}
`
const CaptionOptionTitle = styled.div`
  position: relative;
  display: block;
  margin-bottom: 10px;
  color: #fff;
  span {
    display: inline-block;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      right: 100%;
      top: 50%;
      background-image: linear-gradient(
        to right,
        transparent 50%,
        rgb(255, 255, 255) 0%
      );
      background-position: 0px 0px;
      background-size: 9px 1px;
      background-repeat: repeat-x;
      height: 2px;
      transform: translateY(-50%);
      margin-top: 3px;
      z-index: 1;
      width: 100%;
    }
    &:before {
      display: block;
      content: "";
      background-image: linear-gradient(
        to top,
        transparent 50%,
        rgba(255, 255, 255, 1) 0%
      );
      background-position: bottom;
      background-size: 1px 9px;
      background-repeat: repeat-y;
      width: 2px;
      height: 48px;
      position: absolute;
      top: 50%;
      right: 198%;
    }
  }
`
const BadgeVertical = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`
const BadgeVerticalItem = styled.li`
  margin: 0;
  padding: 0;
  > a {
    display: inline-block;
    max-width: 202px;
    width: 100%;
    &:hover,
    &:focus {
      .badge {
        background-color: #eba300;
        color: #fff;
      }
    }
  }
  + li {
    margin-top: 12px;
  }
`

const CaptionBottom = styled.div`
  text-align: center;
  margin: 0 auto;
  ${BreakpointUp.xxl`
		margin:-100px auto 0 auto;
	`}
`
const CaptionContent = styled.div`
  max-width: 1496px;
  width: 100%;
  margin: 0 auto;
  border-bottom: 1px dashed #fff;
  padding-bottom: 30px;
  ${BreakpointUp.xl`
		padding-bottom:60px;
	`}
  ${BreakpointUp.xxl`
		padding-bottom:100px;
	`}
`
const CaptionFigureTitle = styled.div`
  max-width: 1190px;
  width: 100%;
  margin: 0 auto 15px;
  color: #fff;
  > strong {
    font-family: "Barlow", sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    ${BreakpointUp.md`
			font-size: 28px;
			line-height: 36px;
		`}
    ${BreakpointUp.lg`
			font-size: 32px;
			line-height: 42px;
		`}
		${BreakpointUp.xxl`
			font-size: 42px;
			line-height: 54px;
		`}
  }
  strong,
  a {
    display: inline;
    padding: 0 5px;
  }
  a {
    vertical-align: bottom;
    .btn {
      ${BreakpointDown.xxl`
				padding: 5px 12px;
				font-size: 14px;
			`}
    }
  }
`
const CaptionFigureLead = styled.div`
  max-width: 1190px;
  width: 100%;
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 20px;
  display: none;
  ${BreakpointUp.lg`
	    display: inline-flex;
    `}
  ${BreakpointUp.xxl`
	  font-size: 18px;
	  line-height: 30px;
    `}
`

const HeroSection = () => {
  return (
    <Section xpt="114px" mpt="94px" pt="64px" xpb="0" mpb="0" pb="0">
      <Container maxWidth="100%" pl="3%" pr="3%">
        <Hero>
          <HeroCaption>
            <CaptionTop>
              <CaptionSubTitle>
                Superior Metal <strong>Structures</strong>
              </CaptionSubTitle>
              <CaptionTitle>
                Beautiful Barns, Wonderful Workshops, Colorful Carports, and
                more!
              </CaptionTitle>
            </CaptionTop>
            <CaptionMiddle>
              <Feature>
                <FeatureBody>
                  <FeatureTitle className="h5">
                    <span>Providing Features:</span>
                  </FeatureTitle>
                  <ListFeature>
                    <ListFeatureItem>Customization</ListFeatureItem>
                    <ListFeatureItem>Durability</ListFeatureItem>
                    <ListFeatureItem>Flexibility & Versatility</ListFeatureItem>
                    <ListFeatureItem>Low Maintenance</ListFeatureItem>
                    <ListFeatureItem>Easy & Quick Assembly</ListFeatureItem>
                    <ListFeatureItem>Cost-Efficiency</ListFeatureItem>
                    <ListFeatureItem>Many Choices</ListFeatureItem>
                  </ListFeature>
                </FeatureBody>
              </Feature>
              <CaptionFigure>
                <StaticImage
                  src="../../images/3d-buildings.png"
                  placeholder="blurred"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="3d Buildings"
                />
              </CaptionFigure>
              <CaptionOption>
                <CaptionOptionBody>
                  <CaptionOptionTitle className="h5">
                    <span>Roof Style Options </span>
                  </CaptionOptionTitle>
                  <BadgeVertical>
                    <BadgeVerticalItem>
                      <Link to="/metal-building-roof-guide">
                        <Badge
                          text="Vertical Roof Style"
                          icon={<ArrowRightIcon />}
                        />
                      </Link>
                    </BadgeVerticalItem>
                    <BadgeVerticalItem>
                      <Link to="/metal-building-roof-guide">
                        <Badge
                          text="A-Frame Roof Style"
                          icon={<ArrowRightIcon />}
                        />
                      </Link>
                    </BadgeVerticalItem>
                    <BadgeVerticalItem>
                      <Link to="/metal-building-roof-guide">
                        <Badge
                          text="Regular Roof Style"
                          icon={<ArrowRightIcon />}
                        />
                      </Link>
                    </BadgeVerticalItem>
                  </BadgeVertical>
                </CaptionOptionBody>
              </CaptionOption>
            </CaptionMiddle>
            <CaptionBottom>
              <CaptionContent>
                <CaptionFigureTitle>
                  <strong>
                    Discover Your Dream Metal Building With Our Incredible 3D
                    Designer Tool!
                  </strong>
                  <a
                    href="https://libertymetalstructures.sensei3d.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <PrimaryButton text="START" />
                  </a>
                </CaptionFigureTitle>
                <CaptionFigureLead>
                  Utilize our 3D website designer to create your custom building
                  and see your dream come to life! With this fantastic tool, the
                  steel structure of your imagination is only a few clicks away!
                  While you’re there, you can calculate a quick estimate, too!
                </CaptionFigureLead>
              </CaptionContent>
            </CaptionBottom>
          </HeroCaption>
        </Hero>
      </Container>
    </Section>
  )
}

export default HeroSection
