import React from "react"
import styled from 'styled-components'
import Handpointer from "../../svg/handpointer-red.svg"
import BreakpointUp from "../Media/BreakpointUp"

const Popover = styled.div`
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  border-radius:4px;
  background-color:#fff;
  box-shadow: 0 25px 50px rgb(3 51 102 / 20%);
  text-align:left;
  position: absolute;
  margin-top: -15px;
  top: auto;
  z-index:4;
  left: 0;
  right:0;
  padding:20px;
  ${BreakpointUp.xl`
    padding:40px;
  `}
  ${BreakpointUp.xxl`
    padding:80px;
  `}
    & ul {
		list-style: none;
		margin:0 0 30px;
		padding:0;
		font-weight: 500;
		font-size: 16px;
		line-height: 28px;
        color:#000;
		& li {
			padding-left: 40px;
          vertical-align: text-top;
          margin: 0;            
          strong{
              color:#D51333;
          }
			+ li{
				margin-top:15px;
			}
			&:before {
				content: url(${Handpointer});
        display: inline-block;
        margin-left: -38px;
        width: 38px;
        vertical-align: top;
			}
		}
	}
  p{
    color:#444;
    margin-bottom:10px;
    font-size:14px;
    line-height:24px;
  }
  &.active{
    opacity: 1;
    visibility: visible;
  }
`
const PopOverlay = styled.div`
  opacity: 0;
  visibility: hidden;
  transition: all 500ms ease-in-out;
  pointer-events: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background: rgba(235,244,252,.9);
  transition: all 500ms ease-in-out;
  z-index: 3;
  &.active{
    opacity: 1;
    visibility: visible;
   transform:scale(1.2)
  }
`

function PopoverGetClarity({ isActive, content }) {
    return(
        <>
          <Popover className={`${isActive ? 'active' : ''}`} >
          <div dangerouslySetInnerHTML={{__html:content}}/>
          </Popover>
          <PopOverlay className={`pop-overlay ${isActive ? 'active' : ''}`} />
        </>
    )
}

export default PopoverGetClarity