import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import PrimaryOutlineButton from "../../components/Button/PrimaryOutlineButton"
import BreakpointUp from "../../components/Media/BreakpointUp"
import BreakpointDown from "../../components/Media/BreakpointDown"
import LineArrowRight from "../../components/Icons/LineArrowRight"
import {
  Container,
  Section,
  SectionDescription,
  SectionTitle,
} from "../../components/Section"
import ArticleCard from "../../components/ArticleCard"

const SectionGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
  justify-content: center;
`
const SectionGridCol = styled.div`
  position: relative;
  width: 100%;
  padding: 0 10px;
  ${BreakpointUp.lg`
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  `}
  .card-desc {
    > p {
      color: #444;
      font-weight: 500;
      font-style: italic;
    }
  }
`
const ExploreMore = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  > a {
    width: 100%;
    max-width: 340px;
    .btn {
      width: 100%;
    }
  }
`
const Grid = styled.div`
  display: flex;
  margin-left: -10px;
  margin-right: -10px;
`
const Col = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  ${BreakpointDown.sm`
         padding-left:15px;
         padding-right:15px;
    `}
`

const ArticleSection = ({ data }) => {
  return (
    <Section
      xpt="60px"
      mpt="40px"
      pt="30px"
      xpb="60px"
      mpb="40px"
      pb="30px"
      bgColor="transparent"
    >
      <Container maxWidth="100%" pl="3%" pr="3%">
        <Grid>
          <Col>
            <SectionTitle>You Should Know Better</SectionTitle>
            <SectionDescription maxWidth="1496px">
              <p>
                At Liberty Metal Buildings, we believe that we are building more
                than a structure - we are building a relationship! We want our
                customers to be well informed about the benefits of high-quality
                steel, eco-friendly construction, and the customizable options
                that make metal buildings the best choice for you. The expert
                articles provided here are for your information and enjoyment;
                we know that, soon, you will love metal buildings as much as we
                do!
              </p>
            </SectionDescription>
            <SectionGrid>
              {data.map((item, i) => {
                if (i < 3) {
                  return (
                    <SectionGridCol key={i}>
                      <ArticleCard data={item.node} />
                    </SectionGridCol>
                  )
                }
                return <></>
              })}
            </SectionGrid>
            <ExploreMore>
              <Link to="/blog">
                <PrimaryOutlineButton
                  text="View All Articles"
                  icon={<LineArrowRight />}
                  size="lg"
                />
              </Link>
            </ExploreMore>
          </Col>
        </Grid>
      </Container>
    </Section>
  )
}

export default ArticleSection
